<template>
  <div class="vg_wrapper">
    <el-card>
      <el-row>
        <el-col>
          <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"
            ><i class="el-icon-refresh-right"></i> 刷新</el-button
          >
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <el-table ref="multiTable" :data="tableData" border v-loading="loading" class="mtrTab">
            <el-table-column label="操作人" prop="shwe_stff_name" />
            <el-table-column label="账套" prop="acct_no" />
            <el-table-column label="利器名称" prop="shwe_names" />
            <el-table-column label="操作数量" prop="shwe_num" />
            <el-table-column label="类型">
              <template slot-scope="scope">
                <span v-show="false">{{ scope.$index }}</span
                >未回收
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { shweAPI } from '@api/modules/shwe';
import helper from '@assets/js/helper.js';
export default {
  name: 'ShwePush9002',
  data() {
    return {
      tableData: [],
      loading: true
    };
  },
  created() {
    this.initData();
  },
  mounted() {},
  methods: {
    initData() {
      this.loading = true;
      this.getPushList();
    },
    //获取信息
    getPushList() {
      get(shweAPI.getYesterdayShwe)
        .then(res => {
          if (res.data.code === 0) {
            this.tableData = res.data.data.list;
            setTimeout(() => {
              this.loading = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    buttonRefresh() {
      this.initData();
    }
  }
};
</script>

<style scoped lang="scss">
.vd_completed {
  color: $color-secondary-text;
}
.vd_elimg {
  margin-top: 5px;
  width: 60px;
  height: 60px;
}
.mtrTab {
  margin-top: 10px;
  cursor: pointer;
}
</style>
